/* eslint-disable no-dupe-keys */
<template>
  <v-app-bar
    height="auto"
    elevation="0"
    fixed
    :color="bg === 'white' ? 'white' : 'rgba(255, 251, 249, 0.95)'"
    style="background: rgba(255, 251, 249, 0.95);  width: 100% !important;"
    :class="[icon !== 'x' ? 'appBar-none' : 'appBar']"
    :style="{ background: bg }"
    class="ipad"
  >
    <page-layout>
      <template #Content>
        <div class="tw-flex tw-w-full">
          <div
            class="
              tw-hidden
              lg:tw-flex
              tw-w-full tw-items-center tw-py-5 tw-justify-between
            "
          >
            <!-- web starts here -->
            <!-- first -->
            <img
              :src="logo"
              width="146"
              height="40"
              alt="logo"
              class="tw-cursor-pointer"
              @click="routeToHome"
            />
            <!-- second -->
            <div
              class="tw-flex tw-flex-row tw-items-center"
              style="height: 40px"
            >
              <v-tabs
                v-model="currentTab"
                slider-color="#F66B05"
                active-class="nav-item"
                color="#F66B05"
                :hide-slider="
                  $route.name === 'HomePage' ||
                  $route.name === 'TransportCompanyRegister' ||
                  $route.name === 'Career' ||
                  $route.name === 'Login' ||
                  $route.name === 'RegisterAs'
                "
              >
                <v-tab to="/about" class="nav-item">About</v-tab>
                <v-tab to="/blog">Blog</v-tab>
                <v-tab to="/contact">Contact</v-tab>
              </v-tabs>
            </div>

            <!-- third -->
            <div class="tw-w-auto">
              <router-link
                to="/login"
                class=" tw-w-auto tw-mr-5"

                ><label class="appBar-title">Login</label></router-link
              >
              <router-link to="/register" class="appBar-title signup tw-py-2"
                >Get Started
              </router-link>
            </div>
          </div>

          <!-- web ends here -->
          <div
            class="
              lg:tw-hidden
              tw-w-full tw-flex tw-flex-col tw-h-auto
            "
          >
            <div
              class="
                tw-flex tw-w-full tw-justify-between tw-items-center tw-px-4
              "
            >
              <img :src="logo" width="146" height="40" />
              <vue-feather
                :type="icon"
                @click="toggelIcon"
                class="tw-cursor-pointer"
              />
            </div>
            <div
              class="tw-flex tw-flex-col tw-px-5"
              v-if="icon === 'x'"
              :class="[icon === 'x' ? 'anim' : '']"
            >
              <v-tabs
                vertical
                v-model="currentTab"
                slider-color="#F66B05"
                :hide-slider="
                  $route.name === 'HomePage' ||
                  $route.name === 'Register' ||
                  $route.name === 'Login' ||
                  $route.name === 'Career'
                "
              >
                <v-tab @click="closeToggle" to="/about">About</v-tab>
                <v-tab @click="closeToggle" to="/blog">Blog</v-tab>
                <v-tab @click="closeToggle" to="/contact">Contact</v-tab>
              </v-tabs>
              <div class="item-css tw-flex tw-items-center">
                <h6 class="sign-in tw-mr-5" @click="$router.push({ name: 'Login' })">Login</h6>
                <v-btn
                  class="get-started tw-p-2"
                  text
                  @click="$router.push({ name: 'Register' })"
                  >Get Started</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </template>
    </page-layout>
  </v-app-bar>
</template>

<script>
import PageLayout from "@/components/reuseables/PageLayout";
import logo from "@/assets/logo.svg"
export default {
  name: "HomeNavbar",
  components: {
    PageLayout,
  },
  data() {
    return {
      logo: logo,
      icon: "menu",
      menus: ["About", "Blog", "Contact"],
      bg: "",
      currentTab: "Blog",
    };
  },
  watch: {
    "$route.name": {
      handler: function (val) {
        if (val === "About") {
          this.currentTab = "About";
        } else if (val === "Blog") {
          this.currentTab = "Blog";
        } else if (val === "Contact") {
          this.currentTab = "Contact";
        }
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    toggelIcon() {
      if (this.icon === "menu") {
        this.icon = "x";
      } else {
        this.icon = "menu";
      }
    },
    closeToggle(){
      this.icon = "menu"
    },
    routeToHome() {
      if (this.$route.name !== "HomePage")
        this.$router.push({ name: "HomePage" });
    },
    routeToPages(name) {
      if (name === "Developer") {
        window.open("https://developer-roadpadi.netlify.app", "_self");
      }
    },

    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.bg = "white";
      } else {
        this.bg = "transparent";
      }
    },
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
};
</script>

<style scoped lang="scss">
.v-tab {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--padiGrey);
  text-transform: none;
  @media (max-width: 1024px) {
    max-width: 100% !important;
    justify-content: left;
    padding: 20px;
  }
}
::v-deep .theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}
.ipad {
  width: 100%;
  z-index: 2;
  padding-right: 9rem !important;
  padding-left: 9rem !important;

  @media screen and (min-width: 768px) and (max-width: 1264px) {
    padding: 0.5rem 1rem !important;
  }

  @media screen and (max-width: 768px) {
    padding-top: 1rem;
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
}

.appBar-title {
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 550;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none !important;
  text-align: left;
  color: #F66B05;
  cursor: pointer;
}

.item-css {
  width: 100%;
  height: 76px;
  cursor: pointer;
  font-family: Inter, sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #154b94;
  @media (max-width: 1024px) {
    padding: 10px 20px;
  }
}

.anim {
  transition: 5s;
  transition-timing-function: ease-in-out;
  transform: translateY(0);
  opacity: 1;
  animation: infinite;
}

.item-css:hover {
  height: 76px;
  background: rgba(124, 176, 246, 0.05);
  width: 100%;
  cursor: pointer;
}

.sign-in {
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: none;
  color: #F66B05;
}

.get-started {
  border: 1px solid #F66B05;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #F66B05;
}

.appBar-title.signup {
  background: #F66B05;
  width: 170px;
  height: 48px;
  color: #ffffff;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.v-toolbar__content {
  padding: 4px 0px !important;
}

::v-deep .v-toolbar__content,
.v-toolbar__extension {
  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: rgba(255, 251, 249, 0.95);
}

.nav-item{
  //color: #F66B05;
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
}
</style>