<template>
  <footer class="footer">
    <div
      class="
        tw-flex tw-justify-center
        md:tw-items-start md:tw-flex-row
        tw-flex-col tw-flex-wrap
      ">
      <v-col sm="12" md="4" class="first-box">
        <div class="logo-div">
          <img
            :src="logo"
            alt=""
            @click="routeToHome"
            class="clickable-logo"
          />
        </div>

        <SocialMedia />
      </v-col>
      <v-col sm="6" md="2" class="second-box tw-flex tw-flex-col">
        <p class="header">Company</p>
        <router-link to="/about" class="footer-link">About Us</router-link>
        <router-link to="/" class="footer-link">Volunteer</router-link>
        <router-link to="/" class="footer-link">Out Board of Trustee</router-link>
      </v-col>
      <v-col sm="6" md="2" class="third-box">
        <p class="header">Donate</p>
        <router-link to="/" class="footer-link">Transportation</router-link>
        <router-link to="/" class="footer-link">Balls</router-link>
        <router-link to="/" class="footer-link">Sport Kits</router-link>
        <router-link to="/" class="footer-link">Accommodation</router-link>
        <router-link to="/" class="footer-link">Sport Center</router-link>
      </v-col>
      <v-col sm="6" md="2" class="third-box">
        <p class="header">Resources</p>
<!--        <router-link to="/FAQs" class="footer-link">FAQs</router-link>-->

        <router-link to="/">Blog</router-link>
        <router-link to="/">Media</router-link>
      </v-col>
      <v-col sm="6" md="2" class="third-box">
        <p class="header">Contact</p>
        <p class="footer-link">hello@basketball4buckland.com</p>
        <p class="footer-link">+44 7721 580272</p>
      </v-col>
    </div>

    <div class="right-reserved">
      <v-divider class="tw-pb-5"/>
      <div class="right-reserved-inner">All rights Reserved
        <img :src="resCopy" class="tw-mx-2" style="width: 22px; height: 22px;" alt=""> {{year}}</div>
    </div>
  </footer>
</template>

<script>
import SocialMedia from "../components/reuseables/SocialMedia.vue";
import resCopy from "@/assets/copy.svg"
import logo from "../assets/logo.svg"
export default {
  components: {
    SocialMedia,
  },
  name: "Footer",
  data() {
    return {
      resCopy,
      logo,
      year : ""
    };
  },

  methods: {
    routeToHome() {
      if (this.$route.name !== "HomePage")
        this.$router.push({ name: "HomePage" });
    },
    routeToDeveloper(){
      window.open(process.env.VUE_APP_DEVELOPER_FRONTEND_URL,'_self')
    },
    getYear(){
      let date = new Date()
     this.year= date.getFullYear()
    }
  },
  mounted() {
    this.getYear()
  }
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 10rem 10rem 0 10rem;
  min-height: 70vh;

  @media screen and (max-width: 1264px) {
    padding: 10% 7%;
  }
  @media screen and (max-width: 1024px) {
    padding: 10% 4%;
  }
  position: relative;
  background: #FFFBF9;

  .header {
    font-family: "Raleway", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;
    color: #F66B05;;
  }

  .first-box {
    .logo-div {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    .address-box {
      img {
        margin-top: 7px;
        margin-right: 8px;
      }
    }
    .address-text {
      height: 72px;
      width: 269px;
      font-family: "Inter", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(38, 50, 56, 1);

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .second-box a,
  .third-box a {
    width: 100%;
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 0.6);

    margin-top: 10px;
    text-align: left;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      margin-top: 15px;
    }
  }
  .box-text {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin: 18px 0;

    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }

  .right-reserved {
    //position: absolute;
    margin-top: 10rem;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

  }
  .right-reserved-inner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-right: 5rem !important;
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 0.6);
  }

  .third-box {
    display: flex;
    flex-direction: column;
  }
}
.footer-link{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 10px;

}
</style>
