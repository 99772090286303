var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"ipad",class:[_vm.icon !== 'x' ? 'appBar-none' : 'appBar'],staticStyle:{"background":"rgba(255, 251, 249, 0.95)","width":"100% !important"},style:({ background: _vm.bg }),attrs:{"height":"auto","elevation":"0","fixed":"","color":_vm.bg === 'white' ? 'white' : 'rgba(255, 251, 249, 0.95)'}},[_c('page-layout',{scopedSlots:_vm._u([{key:"Content",fn:function(){return [_c('div',{staticClass:"tw-flex tw-w-full"},[_c('div',{staticClass:"\n            tw-hidden\n            lg:tw-flex\n            tw-w-full tw-items-center tw-py-5 tw-justify-between\n          "},[_c('img',{staticClass:"tw-cursor-pointer",attrs:{"src":_vm.logo,"width":"146","height":"40","alt":"logo"},on:{"click":_vm.routeToHome}}),_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center",staticStyle:{"height":"40px"}},[_c('v-tabs',{attrs:{"slider-color":"#F66B05","active-class":"nav-item","color":"#F66B05","hide-slider":_vm.$route.name === 'HomePage' ||
                _vm.$route.name === 'TransportCompanyRegister' ||
                _vm.$route.name === 'Career' ||
                _vm.$route.name === 'Login' ||
                _vm.$route.name === 'RegisterAs'},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',{staticClass:"nav-item",attrs:{"to":"/about"}},[_vm._v("About")]),_c('v-tab',{attrs:{"to":"/blog"}},[_vm._v("Blog")]),_c('v-tab',{attrs:{"to":"/contact"}},[_vm._v("Contact")])],1)],1),_c('div',{staticClass:"tw-w-auto"},[_c('router-link',{staticClass:" tw-w-auto tw-mr-5",attrs:{"to":"/login"}},[_c('label',{staticClass:"appBar-title"},[_vm._v("Login")])]),_c('router-link',{staticClass:"appBar-title signup tw-py-2",attrs:{"to":"/register"}},[_vm._v("Get Started ")])],1)]),_c('div',{staticClass:"\n            lg:tw-hidden\n            tw-w-full tw-flex tw-flex-col tw-h-auto\n          "},[_c('div',{staticClass:"\n              tw-flex tw-w-full tw-justify-between tw-items-center tw-px-4\n            "},[_c('img',{attrs:{"src":_vm.logo,"width":"146","height":"40"}}),_c('vue-feather',{staticClass:"tw-cursor-pointer",attrs:{"type":_vm.icon},on:{"click":_vm.toggelIcon}})],1),(_vm.icon === 'x')?_c('div',{staticClass:"tw-flex tw-flex-col tw-px-5",class:[_vm.icon === 'x' ? 'anim' : '']},[_c('v-tabs',{attrs:{"vertical":"","slider-color":"#F66B05","hide-slider":_vm.$route.name === 'HomePage' ||
                _vm.$route.name === 'Register' ||
                _vm.$route.name === 'Login' ||
                _vm.$route.name === 'Career'},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',{attrs:{"to":"/about"},on:{"click":_vm.closeToggle}},[_vm._v("About")]),_c('v-tab',{attrs:{"to":"/blog"},on:{"click":_vm.closeToggle}},[_vm._v("Blog")]),_c('v-tab',{attrs:{"to":"/contact"},on:{"click":_vm.closeToggle}},[_vm._v("Contact")])],1),_c('div',{staticClass:"item-css tw-flex tw-items-center"},[_c('h6',{staticClass:"sign-in tw-mr-5",on:{"click":function($event){return _vm.$router.push({ name: 'Login' })}}},[_vm._v("Login")]),_c('v-btn',{staticClass:"get-started tw-p-2",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({ name: 'Register' })}}},[_vm._v("Get Started")])],1)],1):_vm._e()])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }