<template>
  <div
    class="
      tw-flex tw-justify-start tw-items-center
      mt-10
      tw-cursor-pointer
      pl-4
      social-media
    "
  >
    <img
      :src="facebook"
      class="tw-mr-5 social-icon"
      alt="facebook"
      @click="socialPlatform('facebook')"
    />
    <img
      :src="twitter"
      class="tw-mr-5 social-icon"
      alt="twitter"
      @click="socialPlatform('twitter')"
    />
    <img
      :src="instagram"
      class="tw-mr-5 social-icon"
      alt="instagram"
      @click="socialPlatform('instagram')"
    />
    <img
      :src="whatsapp"
      class="social-icon tw-mr-5"
      alt="whatsapp "
      @click="socialPlatform('whatsapp')"
    />

  </div>
</template>

<script>
import facebook from "@/assets/facebook.svg"
import whatsapp from "@/assets/whatsapp.svg"
import instagram from "@/assets/instagram.svg"
import twitter from "@/assets/twitter.svg"
export default {
  name : "SocialMedia",
  data(){
    return{
      facebook,
      whatsapp,
      instagram,
      twitter
    }
  },
  methods: {
    socialPlatform(type) {
      if (type === "instagram") {
        window.open(" https://instagram.com/basketball4buckland", "_blank");
      } else if (type === "whatsapp") {
        window.open("https://wa.me/+447721580272", "_blank");
      } else if (type === "facebook") {
        window.open(
          "https://facebook.com/basketball4buckland",
          "_blank"
        );
      } else if (type === "twitter") {
        window.open(" https://twitter.com/bball4buckland", "_blank");
      } else if (type === "linkedin") {
        window.open("https://www.linkedin.com/company/b4b");
      }
    },
  },
};
</script>

<style>
</style>